import { useEffect, useRef, useState } from "react";
import Paper from "../Paper";
import {
	GetMapGeoJson,
	GetSchoolDistrictsGeoJson,
	GetOutsideOcGeoJson,
	// GetCityGeoJson,
	// GetCityBoundaryJson,
	// GetZipBoundaryJson,
	// GetZipGeoJson,
} from "./GetMapJson";
import {
	getColorByIndex,
	getLinearColorIndex,
	oneToneColors,
	twoToneColors,
} from "./Helpers/BaseLayerColor";
import { useTranslation } from "react-i18next";
import { getLatLonCenter } from "../../lib/helpers";
import Loading from "../loading";
import { GeographiesCache } from "./GeographiesCache";
import { BaseLayerTooltip } from "./Tooltips/BaseLayerTooltip";
import { OverlayTooltip } from "./Tooltips/OverlayTooltip";
import { Cut } from "../../generated/types";
import Map, {
	Layer,
	MapEvent,
	MapLayerMouseEvent,
	Marker,
	NavigationControl,
	Source,
	ViewStateChangeEvent,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./Map.scss";
import mapboxgl from "mapbox-gl";
// import { whiteListedVeterans } from "./data/whiteListedVeterans";
// import { whiteListedHospitals } from "./data/whiteListedHostpitals";
import PinScorecard from "./PinScorecard";
import OutsideAlerter from "./OutsideAlerter";
import getBaselayerData from "../../api/baselayers/getBaselayerData";
import getOverlayData from "../../api/overlays/getOverlayData";
import { CURRENT_YEAR } from "../../constants";
import getBaselayerDataByLayer from "../../api/baselayers/getBaselayerDataByLayer";
import { capitalizeWords, determineGeo } from "./utils";
import CtMapping from "../../lib/ctmapping.json";
import censusGeoJson from "../../lib/maps/geojson/census_tracts.json";
import cityGeoJson from "../../lib/maps/geojson/cities.json";
import zipGeoJson from "../../lib/maps/geojson/zipcodes.json";
import * as turf from "@turf/turf";

type PlaceMarker = {
	latitude: number;
	longitude: number;
};

type MapProps = {
	cut: Cut;
	selected: any;
	onSelect: (arg0: any) => void;
	baseLayer: any;
	overlay: any;
	highlightedGeos: any[];
	selectedQuartile: any;
	selectedCutGeo: any;
	placeMarker?: PlaceMarker;
	reset: any;
	setReset: any;
	selectedCutOptions: any;
};

var MapBoxOverlayPinAdded = false;
var BoundsToFit: any = null;
var BoundsPadding: number = 10;
var hoveredPolygonId: any = null;
var DefaultMapZoom: number = 9;
var IsMobileBrowser: boolean = false;

const MapBoxMap = (props: MapProps) => {
	const { t } = useTranslation();
	const MAP_CENTER: [number, number] = [-117.765395, 33.6656325];
	if (document.body.clientWidth <= 799) {
		DefaultMapZoom = 8.1;
		IsMobileBrowser = true;
		if (document.body.clientWidth <= 360) {
			DefaultMapZoom = 7.95;
		}
	}
	const [position, setPosition] = useState({
		coordinates: MAP_CENTER,
		//zoom: 9,
		zoom: DefaultMapZoom,
	});
	const [ctGeos] = useState(GetMapGeoJson(Cut.CensusTract));
	const [geographies, setGeographies] = useState(null);
	const [noDataGeographies, setNoDataGeographies] = useState(null);
	const [boundaryGeographies, setBoundaryGeographies] = useState(null);
	const [minMaxVals, setMinMaxVals] = useState({ min: 0, max: 0 });
	const [baseLayer, setBaseLayer] = useState(null);
	const [overlay, setOverlay] = useState(null);
	const [colors, setColors] = useState([]);
	const [baseLayerData, setBaseLayerData] = useState([]);
	const [baseLayerDataHash, setBaseLayerDataHash] = useState({});
	const [demographicsLayerDataHash, setDemographicsLayerDataHash] = useState(
		{}
	);
	const [filteredData, setFilteredData] = useState([]);
	const [overlayData, setOverlayData] = useState([]);
	const [pinData, setPinData] = useState<any>({});
	const [pinSelected, setPinSelected] = useState(false);
	const [tooltipHtml, setTooltipHtml] = useState(null);
	const [legendMinimized, setLegendMinimized] = useState(true);
	const [dataLoading, setDataLoading] = useState(false);
	const [beforeMainLayerId, setBeforeMainLayerId] = useState("");
	const mapRef = useRef(null);
	const [polygonType, setPolygonType] = useState(1);

	const MapAccessToken = process.env.REACT_APP_MAPBOX_TOKEN;

	const toggleStreetView = () => {
		if (mapRef === null || mapRef.current === null) {
			return;
		}
		let map = mapRef.current;
		let dMap = map.getMap();
		const layers = map.getStyle().layers;
		let visibility = "none";
		if (
			props.overlay !== null &&
			props.overlay.overlay_type === "street_view"
		) {
			visibility = "visible";
		}
		for (const layer of layers) {
			if (
				layer.id !== "tractsLayer" &&
				layer.id !== "noDataTractsLayer" &&
				layer.id !== "boundaryTractsLayer" &&
				layer.id !== "overlayLayer"
			) {
				//let prop=dMap.getLayoutProperty(layer.id,'visibility');
				//dMap.setLayoutProperty(layer.id,'visibility',(!prop || prop=="visible")?"none":"visible");
				dMap.setLayoutProperty(layer.id, "visibility", visibility);
			}
		}
	};

	useEffect(() => {
		try {
			if (props.baseLayer !== baseLayer || props.overlay !== overlay) {
				if (props.baseLayer !== baseLayer)
					setBaseLayer(props.baseLayer);
				if (props.overlay !== overlay) setOverlay(props.overlay);
			}
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [props.baseLayer, props.overlay, baseLayer, overlay]);

	useEffect(() => {
		try {
			loadBaseLayerData();
			// determine the color scale to use
			if (baseLayer?.layer_type === "demographics") {
				setColors(oneToneColors);
			} else {
				setColors(twoToneColors);
			}
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [baseLayer, props.selectedCutOptions]); // eslint-disable-line

	useEffect(() => {
		try {
			if (overlay === null) {
				setOverlayData([]);
			} else {
				loadOverlayData();
			}
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [overlay]); // eslint-disable-line

	useEffect(() => {
		try {
			let map: mapboxgl.Map = mapRef.current;
			let hGeos = props.highlightedGeos;
			var bounds: mapboxgl.LngLatBounds = null;
			let centerCoordinates = MAP_CENTER;

			if (hGeos && hGeos.length > 0) {
				let centroids = [];
				let idForCenter = "";
				for (let a = 0; a < hGeos.length; a++) {
					if (GeographiesCache[hGeos[a]]) {
						let gc = GeographiesCache[hGeos[a]];
						let coordinates = gc.coordinates[0];
						centroids.push(coordinates);
						if (a === 0) {
							idForCenter = hGeos[a];
							if (props.selectedCutGeo) {
								idForCenter = props.selectedCutGeo.geoId;
							}
						}
						let cIndex = 0;
						if (bounds === null) {
							cIndex = 1;
							if ((polygonType == 2 || gc.coordinates.length > 1) && props.cut === "ZIP_CODE" &&
								props.highlightedGeos.length !== 0) {
								// Create a MultiPolygon GeoJSON feature from your coordinates
								const geojson = {
									"type": "Feature" as const,  // explicitly type as "Feature"
									"geometry": {
										"type": "MultiPolygon" as const,
										"coordinates": [gc.coordinates[0]] // Your provided coordinates array
									},
									"properties": {}
								};


								const centroid = turf.centroid(geojson);
								const coordinates = centroid.geometry.coordinates;
								const point = turf.point(coordinates);
								const buffered = turf.buffer(point, 4, { units: 'kilometers' });
								const bbox = turf.bbox(buffered);

								// bbox returns [minX, minY, maxX, maxY] which is [westLng, southLat, eastLng, northLat]
								bounds = new mapboxgl.LngLatBounds(
									[bbox[0], bbox[1]], // southwest corner [longitude, latitude]
									[bbox[2], bbox[3]]  // northeast corner [longitude, latitude]
								);
							} else {
								bounds = new mapboxgl.LngLatBounds(
									coordinates[0],
									coordinates[1]
								);
							}
						}
						for (let c = cIndex; c < coordinates.length; c++) {
							bounds.extend(coordinates[c]);
						}
					}
				}
				if (centroids.length > 0) {
					centerCoordinates = getLatLonCenter(centroids, idForCenter);
				}
				setPosition({
					...position,
					coordinates: centerCoordinates,
				});
			} else {
				if (!geographies) {
					let zoom = DefaultMapZoom;
					setPosition({
						coordinates: centerCoordinates,
						zoom: zoom,
					});
				} else {
					for (let a = 0; a < geographies.features.length; a++) {
						let gc = geographies.features[a];
						let coordinates = gc.geometry.coordinates[0];
						let cIndex = 0;
						if (bounds === null) {
							bounds = new mapboxgl.LngLatBounds(
								coordinates[0],
								coordinates[1]
							);
							cIndex = 1;
						}
						for (let c = cIndex; c < coordinates.length; c++) {
							bounds.extend(coordinates[c]);
						}
					}
				}
			}

			BoundsPadding = 10;
			if (IsMobileBrowser) {
				BoundsPadding = 0;
			}
			if (bounds) {
				if (props.selected && props.selected.isScoreLoaded) {
					BoundsPadding = 70;
					if (IsMobileBrowser) {
						BoundsPadding = 0;
					}
					BoundsToFit = bounds;
					map.resize();
				} else if (BoundsToFit !== null) {
					BoundsToFit = null;
					if (props.highlightedGeos !== null) {
						if (props.highlightedGeos.length === 1) {
							BoundsPadding = 140;
						} else if (props.highlightedGeos.length > 1) {
							BoundsPadding = 150;
						}
					}
					if (IsMobileBrowser) {
						BoundsPadding = 0;
					}
					map.resize();
					fitBounds(map, bounds);
				} else if (!props.selected) {
					if (props.highlightedGeos !== null) {
						if (props.highlightedGeos.length === 1) {
							BoundsPadding = 140;
						} else if (props.highlightedGeos.length > 1) {
							BoundsPadding = 80;
						}
					}
					if (IsMobileBrowser) {
						BoundsPadding = 0;
					}
					fitBounds(map, bounds);
				}
			}
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [props.highlightedGeos, props.selected]); // eslint-disable-line

	useEffect(() => {
		try {
			let geos: any;

			if (
				(props.cut === "CITY" || props.cut === "ZIP_CODE") &&
				props.selected === null &&
				(props.selectedCutGeo.geoId === "ALL" ||
					props.highlightedGeos.length === 0)
			) {
				if (props.cut === "CITY") {
					geos = JSON.parse(JSON.stringify(cityGeoJson));
				} else {
					geos = JSON.parse(JSON.stringify(zipGeoJson));
				}

				for (let a = 0; a < geos.features.length; a++) {
					let ft = geos.features[a];
					let clr = GetMapFillColor(ft.properties.geoid);
					ft.properties.fillColor = clr.color;
					ft.properties.fillOpacity = clr.opacity;
					ft.properties.id = a;
					ft.properties.feature_type = "baselayer";
					ft.id = a;
					geos.features[a] = ft;
				}
				setGeographies(geos);
				filterNoDataGeographies();
				setPosition({
					...position,
					coordinates: MAP_CENTER,
					zoom: DefaultMapZoom,
				});
				setPolygonType(1);
			} else if (
				props.cut === "CITY" &&
				props.highlightedGeos.length !== 0
			) {
				if (props.selectedCutGeo.cut === "CENSUS_TRACT") {
					setGeographies(null);

					geos = JSON.parse(JSON.stringify(censusGeoJson));

					for (let a = 0; a < geos.features.length; a++) {
						let ft = geos.features[a];

						let clr = GetMapFillColor(ft.properties.geoid);
						ft.properties.fillColor = clr.color;
						ft.properties.fillOpacity = clr.opacity;
						ft.properties.id = a;
						ft.properties.feature_type = "baselayer";
						ft.id = a;
						geos.features[a] = ft;
					}
					setGeographies(geos);
					filterNoDataGeographies();
					setPolygonType(1);
				} else {
					setGeographies(null);
					const intersection: GeoFeature[] = [];

					let coordinates, arrayCoordinates;

					// Filter features based on CITY property
					let filteredFeaturesCityCordinates =
						cityGeoJson.features.filter(
							(feature) =>
								feature.properties.CITY ===
								props.highlightedGeos[0]
						);

					// Extract coordinates from the filtered features
					if (filteredFeaturesCityCordinates.length > 0) {
						coordinates =
							filteredFeaturesCityCordinates[0].geometry
								.coordinates;
					}
					Object.entries(CtMapping).forEach(([key, value]) => {
						const filteredFeatures: GeoFeature[] =
							filterFeaturesByGeoids(censusGeoJson.features, [
								key,
							]);
						filteredFeatures.forEach((feature) => {
							let ft = JSON.parse(JSON.stringify(feature));

							if (value.city.includes(props.highlightedGeos[0])) {
								let clr = GetMapFillColor(ft.properties.geoid);
								ft.properties.fillColor = clr.color;
								ft.properties.fillOpacity = clr.opacity;
								ft.properties.id = geos;
								ft.properties.feature_type = "baselayer";
								ft.id = key;
								if (
									filteredFeaturesCityCordinates[0].geometry.type ==
									"MultiPolygon"
								) {
									arrayCoordinates = getIntersection(
										turf.multiPolygon(coordinates),
										ft
									);
									setPolygonType(2);
								} else {
									arrayCoordinates = getIntersection(
										turf.polygon(coordinates),
										ft
									);
									setPolygonType(1);
								}

								if (arrayCoordinates) {
									// Update ft with properties from the intersection result
									ft.geometry = arrayCoordinates.geometry;
									ft.properties = {
										...ft.properties,
										fillOpacity: clr.opacity,
										id: geos,
										feature_type: "baselayer",
									};
									intersection.push(ft);
								}
							} else {
								ft.properties.fillColor = "#eaeaea";
								ft.properties.fillOpacity = 0.1;
								ft.properties.id = geos;
								ft.properties.feature_type = "baselayer";
								ft.id = key;
								intersection.push(ft);
							}
						});
					});

					const featureCollection: GeoFeatureCollection = {
						type: "FeatureCollection",
						features: intersection,
					};

					geos = JSON.parse(JSON.stringify(featureCollection));
					setGeographies(geos);
					filterNoDataGeographies();
				}
			} else if (
				props.cut === "ZIP_CODE" &&
				props.highlightedGeos.length !== 0
			) {
				if (props.selectedCutGeo.cut === "CENSUS_TRACT") {
					setGeographies(null);

					geos = JSON.parse(JSON.stringify(censusGeoJson));

					for (let a = 0; a < geos.features.length; a++) {
						let ft = geos.features[a];

						let clr = GetMapFillColor(ft.properties.geoid);
						ft.properties.fillColor = clr.color;
						ft.properties.fillOpacity = clr.opacity;
						ft.properties.id = a;
						ft.properties.feature_type = "baselayer";
						ft.id = a;
						geos.features[a] = ft;
					}
					setGeographies(geos);
					filterNoDataGeographies();
					setPolygonType(1);
				} else {
					setGeographies(null);
					const intersection: GeoFeature[] = [];

					let coordinates, arrayCoordinates;

					// Filter features based on ZIP property
					const filteredFeaturesZipCodeCordinates =
						zipGeoJson.features.filter(
							(feature) =>
								feature.properties.geoid ===
								props.highlightedGeos[0]
						);

					// Extract coordinates from the filtered features
					if (filteredFeaturesZipCodeCordinates.length > 0) {
						coordinates =
							filteredFeaturesZipCodeCordinates[0].geometry
								.coordinates;
					}

					Object.entries(CtMapping).forEach(([key, value]) => {
						const filteredFeatures: GeoFeature[] =
							filterFeaturesByGeoids(censusGeoJson.features, [
								key,
							]);
						// _key = key;
						// _coordinates = filteredFeatures[0].geometry.coordinates;
						filteredFeatures.forEach((feature) => {
							let ft = JSON.parse(JSON.stringify(feature));

							if (value.zip.includes(props.highlightedGeos[0])) {
								let clr = GetMapFillColor(ft.properties.geoid);
								ft.properties.fillColor = clr.color;
								ft.properties.fillOpacity = clr.opacity;
								ft.properties.id = key;
								ft.properties.feature_type = "baselayer";
								ft.id = key;

								if (
									filteredFeaturesZipCodeCordinates[0].geometry.type ==
									"MultiPolygon"
								) {
									setPolygonType(2);
									arrayCoordinates = getIntersection(
										turf.multiPolygon(coordinates),
										ft
									);
								} else {
									arrayCoordinates = getIntersection(
										turf.polygon(coordinates),
										ft
									);
									setPolygonType(1);
								}

								if (arrayCoordinates) {
									// Update ft with properties from the intersection result
									ft.geometry = arrayCoordinates.geometry;
									ft.properties = {
										...ft.properties,
										fillOpacity: clr.opacity,
										id: key,
										feature_type: "baselayer",
									};
									intersection.push(ft);
								}
							} else {
								ft.properties.fillColor = "#eaeaea";
								ft.properties.fillOpacity = 0.1;
								ft.properties.id = key;
								ft.properties.feature_type = "baselayer";
								ft.id = key;
								intersection.push(ft);
							}
						});
					});

					const featureCollection: GeoFeatureCollection = {
						type: "FeatureCollection",
						features: intersection,
					};
					geos = JSON.parse(JSON.stringify(featureCollection));
					setGeographies(geos);
					filterNoDataGeographies();
				}
			} else {
				geos = JSON.parse(JSON.stringify(GetMapGeoJson(props.cut)));

				for (let a = 0; a < geos.features.length; a++) {
					let ft = geos.features[a];
					let clr = GetMapFillColor(ft.properties.geoid);
					ft.properties.fillColor = clr.color;
					ft.properties.fillOpacity = clr.opacity;
					ft.properties.id = a;
					ft.properties.feature_type = "baselayer";
					ft.id = a;
					geos.features[a] = ft;
				}
				setGeographies(geos);
				filterNoDataGeographies();
				setPolygonType(1);
			}
		} catch (error) {
			// Handle any errors that occurred during processing
			console.error(
				"An error occurred while processing the city MapBoxMap features:",
				error
			);
		}
	}, [baseLayerDataHash, props.highlightedGeos]); // eslint-disable-line

	useEffect(() => {
		try {
			toggleStreetView();
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [props.overlay]); // eslint-disable-line

	function getIntersection(poly1, poly2) {
		return turf.intersect(turf.featureCollection([poly1, poly2]));
	}

	interface Feature {
		type: string;
		geometry: {
			type: string;
			coordinates: number[][][];
		};
		properties: {
			geoid: string;
		};
	}

	function filterFeaturesByGeoids(
		features: Feature[],
		geoids: string[]
	): Feature[] {
		return features.filter((feature) =>
			geoids.includes(feature.properties.geoid)
		);
	}

	// Define the structure of the individual feature
	interface GeoFeature {
		type: string;
		geometry: {
			type: string;
			coordinates: any[]; // Use `any[]` if coordinates are not strictly defined
		};
	}

	// Define the structure of the feature collection
	interface GeoFeatureCollection {
		type: string;
		features: GeoFeature[];
	}

	useEffect(() => {
		try {
			let geos = GetMapGeoJson(props.cut);
			for (let a = 0; a < geos.features.length; a++) {
				let ft = geos.features[a];
				let id = ft.properties.geoid;
				if (!GeographiesCache[id]) {
					const { coordinates } = ft.geometry;
					const center = getLatLonCenter(coordinates, id);
					GeographiesCache[id] = { id, center, coordinates };
				}
			}
			setGeographies(geos);
			loadDemographicsLayerData();
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [props.cut]); // eslint-disable-line

	useEffect(() => {
		try {
			const { selectedCutGeo } = props;
			const tempFilter = [];

			if (selectedCutGeo?.cut && selectedCutGeo?.geoId) {
				const { cut, geoId } = selectedCutGeo;
				for (let overlay of filteredData) {
					const { census_tract, city, zip } = overlay.content;
					if (cut === "CITY") {
						if (
							geoId.toString().toLowerCase() ===
							city.toString().toLowerCase()
						) {
							tempFilter.push(overlay);
						}
					} else if (cut === "ZIP_CODE") {
						if (
							geoId.toString().toLowerCase() ===
							zip.toString().toLowerCase()
						) {
							tempFilter.push(overlay);
						}
					} else if (cut === "CENSUS_TRACT") {
						if (
							geoId.toString().toLowerCase() ===
							census_tract.toString().toLowerCase()
						) {
							tempFilter.push(overlay);
						}
					}
				}
				setOverlayData(tempFilter);
			} else {
				if (overlay !== null) {
					setOverlayData(filteredData);
				}
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [filteredData, props?.selectedCutGeo]); // eslint-disable-line

	useEffect(() => {
		try {
			const { reset, setReset } = props;
			if (reset) {
				setOverlayData([]);
				setFilteredData([]);
				setOverlay(null);
				setReset(false);
			}
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [props.reset]); // eslint-disable-line

	useEffect(() => {
		try {
			const { selectedCutGeo } = props;
			if (
				selectedCutGeo &&
				selectedCutGeo?.geoId !== "ALL" &&
				props.onSelect
			) {
				const { geoId, cut } = selectedCutGeo;
				props.onSelect({
					geoId,
					cut,
				});
			}
		} catch (err) {
			console.error(`Error: ${err.message}`);
		}
	}, [props.selectedCutGeo]); // eslint-disable-line

	const filterNoDataGeographies = () => {
		if (baseLayerData === null || baseLayerData.length === 0) {
			return;
		}
		let geos = JSON.parse(JSON.stringify(GetMapGeoJson(props.cut)));
		for (let a = 0; a < geos.features.length; a++) {
			let ft = geos.features[a];
			let id = ft.properties.geoid;
			let bld = baseLayerDataHash[id];
			if (!bld || bld.content.value === null) {
				if (props.highlightedGeos && props.highlightedGeos.length > 0) {
					if (
						!props.highlightedGeos.find((val) => {
							return val === id;
						})
					) {
						geos.features.splice(a, 1);
						a--;
						continue;
					}
				}
				ft.properties.fillPattern = "pattern";
				ft.properties.id = a;
				ft.properties.feature_type = "baselayer";
				ft.id = a;
				geos.features[a] = ft;
			} else {
				geos.features.splice(a, 1);
				a--;
			}
		}
		setNoDataGeographies(geos);
	};

	const fitBounds = (map, bounds) => {
		map.fitBounds(bounds, {
			padding: BoundsPadding,
		});
	};

	const loadDemographicsLayerData = async () => {
		try {
			// const { selectedCutOptions } = props;
			// const geo = determineGeo(selectedCutOptions);

			const { data: censusData } = await getBaselayerDataByLayer(
				CURRENT_YEAR,
				"censusTracts",
				"demographics"
			);
			const { data: zipData } = await getBaselayerDataByLayer(
				CURRENT_YEAR,
				"zipCodes",
				"demographics"
			);
			const { data: cityData } = await getBaselayerDataByLayer(
				CURRENT_YEAR,
				"cities",
				"demographics"
			);
			const data = [...censusData, ...zipData, ...cityData];

			let blHash = {};
			for (let a = 0; a < data.length; a++) {
				blHash[data[a].geoId] = data[a].content;
			}
			setDemographicsLayerDataHash(blHash);
		} catch (err) {
			console.error(err);
		}
	};

	const loadBaseLayerData = async () => {
		setDataLoading(true);
		if (!baseLayer) return;

		try {
			const { selectedCutOptions } = props;

			let geo = determineGeo(selectedCutOptions);

			if (selectedCutOptions.city === "ALL") {
				geo = "cities";
			} else if (
				(selectedCutOptions.city !== "ALL" &&
					selectedCutOptions.city !== null) ||
				(selectedCutOptions.zip !== "ALL" &&
					selectedCutOptions.zip !== null)
			) {
				geo = "censusTracts";
			}
			let response;
			response = await getBaselayerData(
				CURRENT_YEAR,
				geo,
				baseLayer.layer_type,
				baseLayer.content_key
			);
			const data = response.data?.filter(
				(ct) => ct.geoId !== "6059021813" && ct.geoId !== "6059980000"
			);

			let blHash = {};
			let min = 0,
				max = 0;

			// loop through the data
			if (data.length > 0) {
				try {
					min = parseFloat(
						parseFloat(data[0].content.value).toFixed(2)
					);
					max = parseFloat(
						parseFloat(data[0].content.value).toFixed(2)
					);
				} catch (e) {
					console.error(e.message);
				}

				// determine the range
				for (let a = 1; a < data.length; a++) {
					let value = parseFloat(
						parseFloat(data[a].content.value).toFixed(2)
					);
					if (value < min) {
						min = value;
					} else if (value > max) {
						max = value;
					}
				}

				// determine the color scale to use
				if (baseLayer?.layer_type) {
					if (baseLayer.layer_type === "demographics") {
						setColors(oneToneColors);
					} else {
						setColors(twoToneColors);
					}
				}

				// get population data
				const populationData = data.map((dt: any) =>
					parseFloat(dt.content.value)
				);

				// update data points
				for (let a = 0; a < data.length; a++) {
					if (data[a].geoId !== 6059021813) {
						let dt = data[a];
						const value = parseFloat(dt.content.value);
						dt.normalizedValue = getLinearColorIndex(
							value,
							populationData,
							colors
						);
						data[a] = dt;
						if (props.selectedCutOptions.city) {
							let key = dt.geoId.toString();
							blHash[key.toUpperCase()] = dt;
						} else {
							blHash[dt.geoId] = dt;
						}
					}
				}
			}
			setMinMaxVals({ min, max });
			setBaseLayerData(data);
			setBaseLayerDataHash(blHash);
			setDataLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	const loadOverlayData = async () => {
		try {
			if (props.overlay === null) {
				setBoundaryGeographies(null);
				setOverlayData([]);
				toggleStreetView();
				return;
			}
			setDataLoading(true);
			if (props.overlay.parent === "school_districts") {
				let geos = JSON.parse(
					JSON.stringify(GetSchoolDistrictsGeoJson())
				);
				for (let a = 0; a < geos.features.length; a++) {
					let c =
						geos.features[a].properties.S_DISTRICT.toLowerCase();
					let propTitle = props.overlay.title.toLowerCase();
					if (propTitle !== "all" && propTitle !== c) {
						geos.features.splice(a, 1);
						a--;
					}
				}
				setOverlayData([]);
				setBoundaryGeographies(geos);
				toggleStreetView();
			} else if (props.overlay.overlay_type === "street_view") {
				setOverlayData([]);
				toggleStreetView();
			} else {
				toggleStreetView();
				const { data } = await getOverlayData(
					props.overlay.overlay_type
				);
				setFilteredData(data);
				setBoundaryGeographies(null);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setDataLoading(false);
		}
	};

	const GetMapFillColor = (id: string): any => {
		try {
			if (baseLayer !== null && baseLayerData.length > 0) {
				let bld = baseLayerDataHash[id];
				if (!bld || bld.content.value === null) {
					if (
						props.highlightedGeos &&
						props.highlightedGeos.length > 0
					) {
						let col;
						if (
							props.highlightedGeos.find((val) => {
								return val === id;
							})
						) {
							//no data but is in highlighted
							return {
								color: "#eaeaea",
								opacity: 1,
								no_data: true,
							};
							//return hatch here
						} else {
							if (
								props.cut === "CITY" &&
								props.selectedCutGeo.cut !== "CENSUS_TRACT" &&
								bld !== undefined &&
								bld.normalizedValue !== null
							) {
								col = getColorByIndex(
									bld.normalizedValue,
									colors
								);
							} else {
								col = "#eaeaea";
							}
							//no data and not in highlighted
							return { color: col, opacity: 1, no_data: true };
						}
					}
					//no data and there are no highlighted
					return { color: "#eaeaea", opacity: 1, no_data: true };
					//return hatch here
				}
				let color = getColorByIndex(bld.normalizedValue, colors);
				let opacity = 1;
				let col;
				if (props.highlightedGeos && props.highlightedGeos.length > 0) {
					if (
						props.highlightedGeos.find((val) => {
							return val === id;
						})
					) {
						//has data and is in highlighted
						//no change in color
					} else {
						if (
							(props.cut === "CITY" ||
								props.cut === "ZIP_CODE") &&
							bld.normalizedValue !== null
						) {
							if (props.highlightedGeos.length > 1) {
								if (id === props.highlightedGeos[0]) {
									col = getColorByIndex(
										bld.normalizedValue,
										colors
									);
								} else {
									col = "#eaeaea";
								}
							} else {
								col = getColorByIndex(
									bld.normalizedValue,
									colors
								);
							}
						} else {
							col = "#eaeaea";
						}

						return { color: col, opacity: opacity }; //has data but not in highlighted
					}
				} else {
					//has data but there are no highlighted
					//no change in color
				}
				return { color: color, opacity: opacity };
			}
			return { color: "#efcfcd", opacity: 1 };
		} catch (error) {
			// Handle any errors that occurred during processing
			console.error(
				"An error occurred while processing GetMapFillColor:",
				error
			);
		}

		//old staging
		// if (baseLayer !== null && baseLayerData.length > 0) {
		//   let bld = baseLayerDataHash[id];
		//   if (!bld || bld.content.value === null) {
		//     if (props.highlightedGeos && props.highlightedGeos.length > 0) {
		//       if (
		//         props.highlightedGeos.find((val) => {
		//           return val === id;
		//         })
		//       ) {
		//         //no data but is in highlighted
		//         return { color: "#eaeaea", opacity: 1, no_data: true };
		//         //return hatch here
		//       } else {
		//         //no data and not in highlighted
		//         return { color: "#eaeaea", opacity: 1, no_data: true };
		//       }
		//     }
		//     //no data and there are no highlighted
		//     return { color: "#eaeaea", opacity: 1, no_data: true };
		//     //return hatch here
		//   }
		//   let color = getColorByIndex(bld.normalizedValue, colors);
		//   let opacity = 1;
		//   if (props.highlightedGeos && props.highlightedGeos.length > 0) {
		//     if (
		//       props.highlightedGeos.find((val) => {
		//         return val === id;
		//       })
		//     ) {
		//       //has data and is in highlighted
		//       //no change in color
		//     } else {
		//       color = "#eaeaea"; //has data but not in highlighted
		//     }
		//   } else {
		//     //has data but there are no highlighted
		//     //no change in color
		//   }
		//   return { color: color, opacity: opacity };
		// }
		// return { color: "#000000", opacity: 1 };
	};

	const handleClick = (id: string, coords: any) => {
		if (props.onSelect) {
			props.onSelect({
				geoId: id,
				cut:
					id.length == 10
						//&& props.selectedCutGeo.cut == "CENSUS_TRACT"
						&& id.substring(0, 4) == '6059'
						? "CENSUS_TRACT"
						: props.cut,
				coordinates: coords,
			});
		}
	};

	const handleLayerMouseEnter = (evt: any, geoId: string) => {
		let layerData = baseLayerDataHash[geoId];
		if (!layerData || props.selected) {
			setTooltipHtml(null);
			return;
		}
		let demographicsLayerData =
			demographicsLayerDataHash[capitalizeWords(geoId)];
		if (!demographicsLayerData) {
			demographicsLayerData = {};
		}
		setTooltipHtml(
			<BaseLayerTooltip
				geoId={geoId}
				layerData={layerData}
				demographicsData={demographicsLayerData}
			></BaseLayerTooltip>
		);
	};

	const handleLayerMouseLeave = (evt: any, geoId: string) => {
		setTooltipHtml(null);
	};

	const handleOverlayMouseEnter = (evt: any, overlayData: any) => {
		if (props.selected) {
			setTooltipHtml(null);
			return;
		}
		setTooltipHtml(
			<OverlayTooltip overlayData={overlayData}></OverlayTooltip>
		);
	};

	const toggleLegend = (val) => {
		setLegendMinimized(!legendMinimized);
	};

	const addMapBoxImages = (map: mapboxgl.Map) => {
		map.loadImage(
			//'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
			"./assets/images/overlay.png",
			(error, image) => {
				if (error) {
					console.error(error);
				} else {
					map.addImage("overlay-pin", image);
					MapBoxOverlayPinAdded = true;
					map.loadImage(
						//'https://docs.mapbox.com/mapbox-gl-js/assets/colorado_flag.png',
						"./assets/images/hatch1.png",
						(error, image) => {
							if (error) {
								console.error(error);
							} else {
								map.addImage("pattern", image);
							}
						}
					);
				}
			}
		);
	};

	const changeMapBoxCursor = (cursor: string) => {
		let elems = document.getElementsByClassName(
			"mapboxgl-canvas-container"
		);
		if (elems.length > 0) {
			let elem: any = elems[0];
			elem.style.cursor = cursor;
		}
	};

	const onMapBoxLoaded = (map: mapboxgl.Map) => {
		addMapBoxImages(map);
		const layers = map.getStyle().layers;
		for (const layer of layers) {
			if (
				layer.id.indexOf("place-city-") === 0 ||
				layer.id.indexOf("marine-label-") === 0 ||
				layer.id.indexOf("state-label-") === 0 ||
				layer.id.indexOf("place-town") === 0 ||
				layer.id.indexOf("place-island") === 0 ||
				layer.id.indexOf("place-village") === 0 ||
				layer.id.indexOf("water") !== -1 ||
				layer.id.indexOf("river") !== -1 ||
				layer.id.indexOf("lake") !== -1 ||
				layer.id.indexOf("parks") !== -1
			) {
				map.setLayoutProperty(layer.id, "visibility", "none");
				map.removeLayer(layer.id);
			}
		}
		for (const layer of layers) {
			if (layer.type === "symbol") {
				setBeforeMainLayerId(layer.id);
				break;
			}
		}
		map.setMaxBounds(map.getBounds());
		toggleStreetView();
		changeMapBoxCursor("auto");
		document.getElementById("map1").style.visibility = "visible";
		// setTimeout(() => {}, 1000);
	};

	if (!geographies) {
		return (
			<Paper>
				<></>
			</Paper>
		);
	}

	let sourceId = "tractsSource";
	let layerId = "tractsLayer";
	let paint: any = {
		"fill-color": ["get", "fillColor"],
		//"fill-opacity": ["get","fillOpacity"],
		"fill-opacity": [
			"case",
			["boolean", ["feature-state", "hover"], false],
			0.9,
			1,
		],
		"fill-outline-color": "#FFFFFF",
	};
	let noDataSourceId = "noDataTractsSource";
	let noDataLayerId = "noDataTractsLayer";
	let noDataPaint: any = {
		"fill-color": "#909090",
		"fill-opacity": 1,
		"fill-outline-color": "#FFFFFF",
		"fill-pattern": ["get", "fillPattern"],
	};
	let boundarySourceId = "boundaryTractsSource";
	let boundaryLayerId = "boundaryTractsLayer";
	let boundaryPaint: any = {
		"line-color": "#000000",
	};
	let overlaySourceId = "overlaySource";
	let overlayLayerId = "overlayLayer";
	let overlayFeatures = {
		type: "FeatureCollection" as const,
		features: [],
	};
	let outsideOcSourceId = "outsideOcSource";
	let outsideOcLayerId = "outsideOcLayer";
	let outsideOcPaint: any = {
		"fill-color": "#eaeaea",
		"fill-opacity": 1,
		"fill-outline-color": "#EAEAEA",
	};

	const scaleNumber = (number: number) => {
		if (number <= 1) {
			return number * 100;
		}
		return number;
	};

	if (MapBoxOverlayPinAdded) {
		for (let a = 0; a < overlayData.length; a++) {
			let ov = overlayData[a];
			let ft = {
				id: (a + 1).toString(),
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [
						Number(ov.content.longitude),
						Number(ov.content.latitude),
					],
				},
				properties: {
					id: (a + 1).toString(),
					feature_type: "overlay",
					overlay_data: ov,
				},
			};
			overlayFeatures.features.push(ft);
		}
	}

	return (
		<>
			{geographies !== null && (
				<>
					<Map
						id="map1"
						ref={mapRef}
						style={{
							width: "100%",
							height: "100%",
							backgroundColor: "#eaeaea",
							visibility: "hidden",
						}}
						mapStyle="mapbox://styles/mapbox/streets-v9"
						interactiveLayerIds={[layerId, overlayLayerId]}
						mapboxAccessToken={MapAccessToken}
						initialViewState={{
							longitude: MAP_CENTER[0],
							latitude: MAP_CENTER[1],
							zoom: DefaultMapZoom,
						}}
						zoom={position.zoom}
						onRender={(e: MapEvent) => { }}
						onLoad={(e: MapEvent) => {
							let map: mapboxgl.Map = e.target;
							onMapBoxLoaded(map);
						}}
						onWheel={(e: any) => {
							e.preventDefault();
						}}
						onZoom={(e: ViewStateChangeEvent) => {
							setPosition({
								...position,
								zoom: e.viewState.zoom,
							});
						}}
						onMouseDown={(e: MapLayerMouseEvent) => {
							changeMapBoxCursor("grab");
						}}
						onMouseUp={(e: MapLayerMouseEvent) => {
							changeMapBoxCursor("auto");
						}}
						onMoveEnd={(e: ViewStateChangeEvent) => {
							setPosition({
								...position,
								coordinates: [
									e.viewState.longitude,
									e.viewState.latitude,
								],
							});
						}}
						onMouseMove={(e: MapLayerMouseEvent) => {
							let map = mapRef.current;
							if (e.features?.length > 0) {
								let ft = e.features[0];
								if (
									ft.properties.feature_type ===
									"baselayer" &&
									ft.properties.geoid
								) {
									handleLayerMouseEnter(
										null,
										ft.properties.geoid
									);
									if (
										props.selected &&
										props.selected.geoId ===
										ft.properties.geoid
									) {
										changeMapBoxCursor("auto");
									} else {
										changeMapBoxCursor("pointer");
									}
								} else if (
									ft.properties.feature_type === "overlay"
								) {
									handleOverlayMouseEnter(
										null,
										JSON.parse(ft.properties.overlay_data)
									);
									changeMapBoxCursor("auto");
								}
								if (e.features?.length > 1) {
									ft = e.features[1];
								}
								if (hoveredPolygonId !== null) {
									map.setFeatureState(
										{
											source: sourceId,
											id: hoveredPolygonId,
										},
										{ hover: false }
									);
								}
								hoveredPolygonId = ft.id;
								map.setFeatureState(
									{ source: sourceId, id: hoveredPolygonId },
									{ hover: true }
								);
							} else {
								changeMapBoxCursor("auto");
								handleLayerMouseLeave(null, "");
								if (hoveredPolygonId !== null) {
									map.setFeatureState(
										{
											source: sourceId,
											id: hoveredPolygonId,
										},
										{ hover: false }
									);
								}
								hoveredPolygonId = null;
							}
						}}
						onMouseEnter={(e: MapLayerMouseEvent) => { }}
						onMouseLeave={(e: MapLayerMouseEvent) => {
							let map = mapRef.current;
							handleLayerMouseLeave(null, "");
							if (hoveredPolygonId !== null) {
								map.setFeatureState(
									{ source: sourceId, id: hoveredPolygonId },
									{ hover: false }
								);
							}
							hoveredPolygonId = null;
						}}
						onResize={(e: MapEvent) => {
							if (BoundsToFit) {
								e.target.fitBounds(BoundsToFit, {
									padding: BoundsPadding,
								});
							}
						}}
						onClick={(e: MapLayerMouseEvent) => {
							if (e.features?.length > 0) {
								const ft = e.features[0];
								if (ft.properties.feature_type === "overlay") {
									setPinData(
										JSON.parse(ft.properties.overlay_data)
									);
									setPinSelected(true);
								} else {
									let id = e.features[0].properties.geoid;
									handleClick(
										id,
										(e.features[0].geometry as any)
											.coordinates
									);
									handleLayerMouseLeave(null, "");
								}
								// if (props.cut === "CITY") {
								//   let lngLat = e.lngLat;
								//   const { lng, lat } = lngLat;
								//   setPosition({
								//     coordinates: [lng, lat],
								//     zoom:10
								//   });
								// }
							}
						}}
					>
						<Source
							key={sourceId}
							id={sourceId}
							type="geojson"
							data={geographies}
						></Source>
						{props.cut === "CENSUS_TRACT" &&
							props.highlightedGeos.length !== 0 && (
								<Source
									key={"ctGeos"}
									id={"ctGeos"}
									type="geojson"
									data={ctGeos}
								></Source>
							)}

						{beforeMainLayerId !== "" && (
							<>
								{/* background overlay */}
								<Layer
									type="background"
									id="backLayer"
									beforeId={beforeMainLayerId}
									paint={{
										"background-color": "#eaeaea",
										"background-opacity": 1,
									}}
								></Layer>

								{props.highlightedGeos.length === 1 &&
									props.cut !== "CENSUS_TRACT" && (
										<Layer
											id={"ctGeosLayer"}
											beforeId={beforeMainLayerId}
											source={"ctGeos"}
											type="fill"
											paint={paint}
										></Layer>
									)}

								<Layer
									id={layerId}
									beforeId={beforeMainLayerId}
									source={sourceId}
									type={
										props.highlightedGeos.length === 1
											? "background"
											: "fill"
									}
									paint={paint}
								></Layer>

								{/* street view layer */}
								{props?.overlay?.overlay_type ===
									"street_view" && (
										<Layer
											id={outsideOcLayerId}
											source={outsideOcSourceId}
											type="fill"
											paint={outsideOcPaint}
										></Layer>
									)}
							</>
						)}

						<Source
							key={outsideOcSourceId}
							id={outsideOcSourceId}
							type="geojson"
							data={GetOutsideOcGeoJson()}
						></Source>
						<Layer
							id={noDataLayerId}
							source={noDataSourceId}
							type="fill"
							paint={noDataPaint}
						></Layer>

						{/* hidden geos */}
						{props.highlightedGeos.length !== 1 && (
							<>
								<Source
									key={noDataSourceId}
									id={noDataSourceId}
									type="geojson"
									data={noDataGeographies}
								></Source>
								<Layer
									id={noDataLayerId}
									source={noDataSourceId}
									type="fill"
									paint={noDataPaint}
								></Layer>
							</>
						)}

						{boundaryGeographies !== null && (
							<>
								<Source
									key={boundarySourceId}
									id={boundarySourceId}
									type="geojson"
									data={boundaryGeographies}
								></Source>
								<Layer
									id={boundaryLayerId}
									source={boundarySourceId}
									type="line"
									paint={boundaryPaint}
								></Layer>
							</>
						)}

						{props.placeMarker && (
							<>
								<Marker
									longitude={props.placeMarker.longitude}
									latitude={props.placeMarker.latitude}
									color="red"
									anchor="center"
								></Marker>
							</>
						)}

						<Source
							key={overlaySourceId}
							id={overlaySourceId}
							type="geojson"
							data={overlayFeatures}
						></Source>
						<Layer
							id={overlayLayerId}
							source={overlaySourceId}
							type="symbol"
							layout={{
								"icon-image": "overlay-pin",
								"icon-anchor": "bottom",
							}}
						></Layer>

						<NavigationControl
							showCompass={false}
							position="top-left"
						></NavigationControl>
					</Map>
				</>
			)}
			{baseLayer !== null && baseLayerData.length > 0 && (
				<div
					className="bottom-text-info"
					onMouseEnter={() => {
						toggleLegend(legendMinimized);
					}}
					onMouseLeave={() => toggleLegend(!legendMinimized)}
				>
					{!legendMinimized && (
						<div className="bottom-text-info-top">
							{baseLayer.layer_type === "spi" && (
								<p>{t("legend_text_spi")}</p>
							)}
							{baseLayer.layer_type === "cdc" && (
								<p>{t("legend_text_cdc")}</p>
							)}
							{baseLayer.layer_type === "demographics" && (
								<p>{t("legend_text_demographics")}</p>
							)}
						</div>
					)}
					<div>
						<b>{t(baseLayer.title_key)}</b>
					</div>
					<div style={{ float: "left" }}>
						<div className="flex flex-row items-center">
							<div className="mr-2">
								{scaleNumber(minMaxVals.min)}
							</div>
							{/* <div className="social-progress-indicator"></div> */}
							<div
								className={
									baseLayer?.layer_type === "demographics"
										? "indicator-bar-legend-one-tone"
										: "indicator-bar-legend-two-tone"
								}
								style={{ width: "200px" }}
							></div>
							<div className="ml-2">
								{scaleNumber(minMaxVals.max)}
							</div>
						</div>
					</div>
					<div style={{ float: "right" }}>
						{legendMinimized && (
							<button
								onClick={() => {
									toggleLegend(!legendMinimized);
								}}
								className="legend-toggle"
							>
								{t("more")}
							</button>
						)}
						{!legendMinimized && (
							<button
								onClick={() => {
									toggleLegend(!legendMinimized);
								}}
								className="legend-toggle"
							>
								{t("less")}
							</button>
						)}
					</div>
					<div style={{ clear: "both" }}></div>
					{!legendMinimized && (
						<div className="bottom-text-info-bottom">
							Sources: SPI, CDC PLACES Local Data, American
							Community Survey, First 5 OC, OC Health Care Agency
							and CA Oppotunity Zone
						</div>
					)}
				</div>
			)}
			{tooltipHtml !== null && (
				<div className="top-text-info tooltip-info">
					<div className="bottom-text-info-top">{tooltipHtml}</div>
				</div>
			)}
			{pinSelected && (
				<OutsideAlerter onClose={() => setPinSelected(false)}>
					<div
						className="partial-scorecard-popup"
						style={{ maxWidth: "400px", height: "250px" }}
					>
						<div className="popup-header">
							<div className="title">Overlay Information</div>
							<div
								className="close"
								onClick={() => setPinSelected(false)}
							>
								<img
									src="./assets/images/close_white.png"
									style={{ width: "20px" }}
									alt=""
								/>
							</div>
						</div>
						<div className="popup-content">
							<PinScorecard data={pinData.content} />
						</div>
					</div>
				</OutsideAlerter>
			)}
			{dataLoading && <Loading></Loading>}
		</>
	);
};

export default MapBoxMap;
