import { Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { DropdownProps } from "./types";
import DropdownInput from "./input";
import dropdownStyle from "./index.module.scss";
import { DropdownRenderOptions } from "./renderoptions";
import { useTranslation } from "react-i18next";

const Dropdown = (props: DropdownProps<any>) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(null);
	const [inputText, setInputText] = useState<string>("");
	const [search, setSearch] = useState<string>("");
	const [showOptions, setShowOptions] = useState(false);
	const [clickedAway, setClickedAway] = useState(false);
	const ref = useRef(null);

	useClickAway(ref, (evt) => {
		setClickedAway(true);
		setShowOptions(false);
		if (inputText === "" && value) {
			setInputText(value.title);
		}
	});

	useEffect(() => {
		setValue(props.value);
		if (!props.value) {
			setInputText("");
		} else {
			setInputText(props.value.titleKey || props.value.title);
			setSearch("");
		}
	}, [props.value, t]);

	const inputFocus = (evt) => {
		setClickedAway(false);
		setShowOptions(true);
	};

	const inputClick = (evt) => {
		if (clickedAway) {
			setClickedAway(false);
			return;
		} else if (showOptions) {
			return;
		}
		setShowOptions(!showOptions);
	};

	const inputChange = (evt) => {
		if (props.autocomplete === true) {
			setSearch(evt.target.value.toUpperCase());
			setInputText(evt.target.value.toUpperCase());
		}
	};

	const optionClick = (opt) => {
		// if (opt.selectable !== false && (!opt.level || opt.level >= 3)) {
		// 	if (opt?.level !== 0) {
		// 		setShowOptions(false);
		// 	}
		// }
		if (!opt.selectable) {
			setShowOptions(false);
		}
		props.onChange(opt);
	};

	const toggleClick = (opt) => {
		props.onToggle?.call(this, opt);
	};

	const closeClick = () => {
		setValue(null);
		setShowOptions(false);
		props.onClear();
	};

	const searchable = props.autocomplete === true;
	const readonly = !searchable;

	let options = props.options;
	if (search !== "") {
		options = props.options.filter((val, idx, arr) => {
			return val.title.indexOf(search) !== -1;
		});
	}
	//let inputValue=search==""?(props.value?props.value.title:""):search;
	let inputValue = inputText;
	const renderer = (
		<DropdownRenderOptions
			options={options}
			value={value}
			onChange={optionClick}
			onToggle={toggleClick}
		></DropdownRenderOptions>
	);
	return (
		<div className="relative">
			<span className="inline-block w-full rounded-md shadow-sm">
				<DropdownInput
					placeholder={props.placeholder}
					value={inputValue}
					onClick={inputClick}
					onChange={inputChange}
					onFocus={inputFocus}
					readonly={readonly}
					onSubmit={() => {
						props.onChange({
							id: inputValue,
							title: inputValue,
						});
						setShowOptions(false);
					}}
				/>
			</span>
			{props.showClose !== false && props.onClear && props.value && (
				<div className={dropdownStyle.close} onClick={closeClick}>
					<img src="./assets/images/close.png" alt="" />
				</div>
			)}
			<Transition
				show={showOptions}
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className="z-10 absolute mt-1 w-full bg-white shadow-lg"
			>
				<div ref={ref} style={{ maxHeight: "250px", overflow: "auto" }}>
					{renderer}
				</div>
			</Transition>
		</div>
	);
};

export default Dropdown;
